<template>
  <div class="newjob">
    <TheHeader title="Manage New Job"></TheHeader>
    <v-container v-if="job" fill-height fluid>
      <v-row v-if="!jobCanBeManaged">
        <v-col>
          <JobMessage icon="mdi-eye" heading="View-Only Mode" message="You can't accept this job as the luggage driver. Once the main driver accepts it, it will be moved to your upcoming jobs list."></JobMessage>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <JobDetails :job="job" :hidden="false"></JobDetails>
        </v-col>
      </v-row>
      <v-row v-if="jobCanBeManaged">
        <v-col>
          <v-form ref="form" v-model="valid" v-on:submit.prevent="submit">
            <div class="font-weight-regular"> Please confirm acceptance of this Job</div>
            <v-radio-group v-model="accepted"  class="mb-3" :rules="[v => !!v || 'You must accept or decline the job']">
              <v-radio value="yes" name="accepted" label="Accept"></v-radio>
              <v-radio value="no" name="accepted" label="Decline"></v-radio>
            </v-radio-group>

            <v-textarea v-if="accepted == 'no'" :required="accepted == 'no'" :rules="declineReasonRules" v-model="declineReason" name="decline-reason" outlined label="Decline Reason" placeholder="Please indicate why this job has been declined"></v-textarea>

            <v-btn type="submit" color="success" block>
              Submit Response
            </v-btn>
          </v-form>
        </v-col>
      </v-row>

      <v-snackbar :value="errors" :timeout="this.$messageTimeout" color="error">
        <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
      </v-snackbar>

      <BaseConfirmationDialog
        :handler="acceptJob"
        :show="dialog"
        @cancel="dialog=false"
        :heading="`${ accepted == 'yes' ? 'Accept' : 'Decline' } Job`"
        :message="`Are you sure you want to ${ accepted == 'yes' ? 'accept' : 'decline' } this job?`"
        :confirm-button-text="`${ accepted == 'yes' ? 'Accept' : 'Decline' }`">
      </BaseConfirmationDialog>
    </v-container>
  </div>
</template>

<script>
import BaseConfirmationDialog from '@/components/BaseConfirmationDialog.vue'
import TheHeader from '@/components/TheHeader.vue'
import JobMessage from '@/components/JobMessage.vue'
import JobDetails from '@/components/JobDetails.vue'
import { mapState } from 'vuex'
import { canManageJob, jobStatusCheckAndRedirect } from '@/services/driverService.js'

export default {
  name: 'Jobs',
  data: () => ({
    job: null,
    valid: true,
    declineReasonRules: [
      v => !!v || 'Decline reason is required',
    ],
    accepted: '',
    declineReason: '',
    dialog: false,
  }),
  components: {
    BaseConfirmationDialog,
    TheHeader,
    JobDetails,
    JobMessage,
  },
  computed: {
    ...mapState({
      errors: state => state.job.errors
    }),
    jobCanBeManaged() {
      return canManageJob(this.job)
    },
  },
  created() {
    this.getJob()
  },
  methods: {
    getJob() {
      this.$store.dispatch('getJob', {
        jobId: this.$route.params.id
      })
      .then(result => {
        this.job = result.data

        // Redirect if the job status indicates it's no longer a new job.
        jobStatusCheckAndRedirect(this.job)
      })
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.dialog = true
      }
    },
    acceptJob() {
      this.dialog = false
      this.$store.dispatch('acceptJob', {
        jobId: this.job.id,
        accept: this.accepted,
        declineReason: this.declineReason
      })
      .then(result => {
        this.$router.push({ name: 'message', params: { text: result.message }})
      })
    }
  }
}
</script>
