/**
 * This directive facilitates displaying a Sign In With Google button using Google's identity service.
 * The code borrows ideas from eduardova/vue-google-identity-login-btn package.
 */

import Vue from 'vue'

export default Vue.directive('google-sign-in-button', {
  bind: function (el, binding, vnode) {

    checkComponentMethods()

    const clientId = binding.value.clientId
    const googleButton = el
    const googleButtonConfig = {
      type: binding.value.type || "standard",
      shape: binding.value.shape || "rectangular",
      theme: binding.value.theme || "outline",
      text: binding.value.text || "signin_with",
      size: binding.value.size || "large",
      locale: binding.value.locale || "en-US",
      logo_alignment: binding.value.logo_alignment || "left",
      width: binding.value.width || 280
    }

    const googleSignInAPI = document.createElement('script')
    googleSignInAPI.setAttribute('src', 'https://accounts.google.com/gsi/client')
    document.head.appendChild(googleSignInAPI)
    googleSignInAPI.onload = initGoogleButton

    function initGoogleButton() {
      // The comment below prevents an ESLint error about google being undefined.
      /*global google*/
      /*eslint no-undef: "error"*/
      google.accounts.id.initialize({
        client_id: clientId,
        callback: OnSuccess
      })

      google.accounts.id.renderButton(
        googleButton,
        googleButtonConfig
      )
    }

    function OnSuccess(response) {
      vnode.context.OnGoogleAuthSuccess(response.credential)
    }

    function checkComponentMethods() {
      if(!el.id)
        throw new Error('The google sign in button container requires an "id" attribute.')

      if (!vnode.context.OnGoogleAuthSuccess)
        throw new Error('The method onGoogleAuthSuccess must be defined on the component.')
    }
  }
});
