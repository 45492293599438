import axios from 'axios'
import { getToken, forceLogout } from '@/services/authService.js'

// Create and configure an axios instance to use as the api client
const ApiClient = axios.create()

ApiClient.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
ApiClient.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'

// Include the authorization header and token when making requests if there's one set
ApiClient.interceptors.request.use(config => {
  if (getToken()) {
    config.headers.common['Authorization'] = "Bearer " + getToken()
  }
  return config
});

// Intercept the response to determine if the API has given us some specific action
// to take by way of an error code, e.g force logout the user if we get error code 1000.
ApiClient.interceptors.response.use(response => {
    return response
  }, error => {
    // Force logout if we get error code 1000 which indicates we should terminate authentication.
    if (error.response.data.code === 1000 || error.response.status === 401) {
      forceLogout(error.response.data.code === 1000 ? error.response.data.messages : 'Your session has expired, login again to continue working.')
    }
    return Promise.reject(error)
  }
);

export default ApiClient
