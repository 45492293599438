<template>
  <v-container id="message" fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" class="pa-8 text-center">
        <div><v-icon size="100" color="success"> mdi-check-circle </v-icon></div>
      </v-col>
      <v-col cols="12" class="text-center">
        <div class="text-h4 mt-5">Success</div>
      </v-col>
      <v-col cols="12" class="text-center">
        <div class="my-5">{{ text }}</div>
      </v-col>
      <v-col cols="12">
        <v-btn @click="redirect" block color="success">Continue</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Message",
  data: () => ({
    text: '',
  }),
  created() {
    this.getMessage()
  },
  methods: {
    redirect() {
      this.$router.push(this.$route.params.redirect ? this.$route.params.redirect : "/")
    },
    getMessage() {
      this.text = this.$route.params.text ? this.$route.params.text : ''
    }
  },
}
</script>
