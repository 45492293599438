<template>
  <v-container id="login" fill-height class="success">
    <v-snackbar :value="persistentMessages" :timeout="-1" :top="true" color="warning" content-class="blue-grey--text text--darken-4">
      <div v-for="(message, key) in persistentMessages" :key="key">{{ message }}</div>
    </v-snackbar>
    <v-row align="center" justify="center">
      <v-col cols="12" class="pa-10 text-center">
        <div class="text-h2 white--text">J<v-icon x-large color="white"> mdi-steering </v-icon>DA</div>
      </v-col>
      <v-col cols="12">
        <div id="google-sign-in-btn" class="mx-auto text-center" v-google-sign-in-button="{clientId: clientId}"></div>
      </v-col>
    </v-row>
    <v-snackbar :value="errors" :timeout="this.$messageTimeout" color="error">
      <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import GoogleSignInButton from '@/directives/googleSignInButton.js'

export default {
  name: "Login",
  data() {
    return {
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    }
  },
  directives: {
    GoogleSignInButton,
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      persistentMessages: state => state.auth.persistentMessages,
    }),
  },
  methods: {
    OnGoogleAuthSuccess(idToken) {
      this.$store
        .dispatch("authenticate", idToken)
        .then(() => this.$router.push("/"))
    },
    OnGoogleAuthFail(error) {
      this.$store.commit('setErrors', error)
    },
  },
}
</script>


<style scoped>

:deep(#google-sign-in-btn) {
  max-width: 280px;
  animation: fadeInAnimation ease 2.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
    }
}

:deep(#google-sign-in-btn iframe) {
  transform: scale(1.185);
}

</style>
