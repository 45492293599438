import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0062C1',
        secondary: '#556171',
        accent: '#00C780',
        error: '#A3462F',
        info: '#567B97',
        success: process.env.VUE_APP_TARGET_ENV === 'production' ? '#01714d' : '#CC4200',
        warning: '#E78430',
      },
    },
  },
});
