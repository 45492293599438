import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth.js'
import job from '@/store/modules/job.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    job,
  }
})
