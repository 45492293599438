export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  created() {
    // Listen for our custom event from the service worker registration.
    // Setting this option to true allows the listener to be called only once AND removes the listener once invoked.
    document.addEventListener('serviceWorkerUpdated', this.updateAvailable, { once: true })

    // Reload the page while preventing multiple refreshes.
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!this.refreshing) {
        window.location.reload()
        this.refreshing = true
      }
    })
  },
  methods: {
    // Store the service worker registration so we can send it a message.
    // We use `updateExists` to control the alert shown to the user when they need to refresh because there's an update.
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },

    // Called when the user accepts the update.
    refreshApp() {
      this.updateExists = false
      // Update the app immediately by sending "skip waiting" message and activating the new service worker only if the current service worker is waiting.
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
  },
}
