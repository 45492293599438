import ApiService from '@/services/apiService.js'

const state = {
  currentJob: null,
  newJobs : [],
  upcomingJobs : [],
  activeJobs : [],
  completedJobs : [],
  errors: null,
}

const mutations = {
  setCurrentJob(state, { job }) {
    state.currentJob = job
  },
  setNewJobs(state, { jobs }) {
    state.newJobs = jobs
  },
  setUpcomingJobs(state, { jobs }) {
    state.upcomingJobs = jobs
  },
  setActiveJobs(state, { jobs }) {
    state.activeJobs = jobs
  },
  setCompletedJobs(state, { jobs }) {
    state.completedJobs = jobs
  },
  setErrors(state, errors) {
    state.errors = null
    if (Array.isArray(errors)) {
      state.errors = errors
    } else {
      state.errors = [errors]
    }
  },
  resetErrors(state) {
    state.errors = null
  }
}

const actions = {
  getJob({ commit }, data) {
    return new Promise(resolve => {
      // Get a single job
      ApiService.getJob(data)
        .then(response => {
          if (response.data.success) {
            commit('setCurrentJob', response.data.data)
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  getNewJobs({ commit }) {
    return new Promise(resolve => {
      // Get the new jobs for the driver
      ApiService.getNewJobs()
        .then(response => {
          if (response.data.success) {
            commit('setNewJobs', response.data.data)
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  getUpcomingJobs({ commit }) {
    return new Promise(resolve => {
      // Get the upcoming jobs for the driver
      ApiService.getUpcomingJobs()
        .then(response => {
          if (response.data.success) {
            commit('setUpcomingJobs', response.data.data)
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  getActiveJobs({ commit }) {
    return new Promise(resolve => {
      // Get the active jobs for the driver
      ApiService.getActiveJobs()
        .then(response => {
          if (response.data.success) {
            commit('setActiveJobs', response.data.data)
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  getCompletedJobs({ commit }) {
    return new Promise(resolve => {
      // Get the completed jobs for the driver
      ApiService.getCompletedJobs()
        .then(response => {
          if (response.data.success) {
            commit('setCompletedJobs', response.data.data)
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  acceptJob({ commit }, data) {
    return new Promise(resolve => {
      // Marks a job as accepted or declined by the driver
      ApiService.acceptJob(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  startJob({ commit }, data) {
    return new Promise(resolve => {
      // Set a job as started
      data.status = 'started'
      ApiService.setJobCommencementStatus(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  markAsNoshow({ commit }, data) {
    return new Promise(resolve => {
      // Mark a job as a no show
      data.status = 'noshow'
      ApiService.setJobCommencementStatus(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  reportProblem({ commit }, data) {
    return new Promise(resolve => {
      // Mark a job as having a problem
      data.status = 'problem'
      ApiService.setJobCommencementStatus(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  finishJob({ commit }, data) {
    return new Promise(resolve => {
      // Finishes an active job
      ApiService.finishJob(data)
        .then(response => {
          if (response.data.success) {
            resolve(response.data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  clearJobErrors({ commit }) {
    commit('resetErrors')
  },
}

const getters = {
  newJobs: state => state.newJobs,
  newJob: (state) => (jobId) => state.newJobs.find( job => job.id == jobId),
  upcomingJobs: state => state.upcomingJobs,
  upcomingJob: (state) => (jobId) => state.upcomingJobs.find( job => job.id == jobId),
  activeJobs: state => state.activeJobs,
  activeJob: (state) => (jobId) => state.activeJobs.find( job => job.id == jobId),
  completedJobs: state => state.completedJobs,
  completedJob: (state) => (jobId) => state.completedJobs.find( job => job.id == jobId),
}

export default {
  state,
  actions,
  mutations,
  getters
}
