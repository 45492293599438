import Vue from 'vue'
import moment from 'moment'

Vue.filter('humanizeTime', function (value) {
  if (!value) {
    return ''
  }

  // Format and return the time
  return moment(value.toString(), 'HH:mm:ss').format('h:mm A')
})

Vue.filter('humanizeDateTime', function (value) {
  if (!value) {
    return ''
  }

  // Format and return the date
  return moment(value.toString()).format('ddd, MMMM D, YYYY, h:mm A')
})

Vue.filter('currency', function (value) {
  if (!value && value !== 0) {
    return ''
  }

  // Format and return the number
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 2
  })
  return formatter.format(value)
})
