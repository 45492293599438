<template>
  <v-app>
    <v-main>
      <router-view/>
      <v-snackbar :value="updateExists" :timeout="-1" color="primary">
        An update to JoDA is available
        <v-btn text @click="refreshApp">
          Update Now
        </v-btn>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import update from '@/mixins/update'

export default {
  name: 'App',
  mixins: [update],
}
</script>

<style>
  .borderless {
    border-width: 0 !important;
  }

  .pre-formatted {
    white-space: pre-wrap;
  }

  .passenger-count-icon {
    position:relative;
  }

  .passenger-count-icon span {
    position: absolute;
    z-index: 1;
    line-height: 0.9em;
    top: 0;
    left: 17px;
    font-size: 0.9em;
    font-weight: 900;
  }

  ::v-deep .theme--light.v-chip {
    color: #6C757D;
  }
</style>
