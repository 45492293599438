<template>
  <div class="home">
    <TheHeader title="My Jobs"></TheHeader>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0">
          <v-tabs v-model="tab" :show-arrows="false" background-color="transparent" grow>
            <v-tab v-for="item in items.tabs" :key="item.title" :href="'#' + item.ref">{{ item.title }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs-items v-model="tab" background-color="light-grey">
            <v-tab-item v-for="item in items.tabs" :key="item.title" :value="item.ref">
                <v-alert dense text color="orange">{{ item.tip }}</v-alert>
                <div v-for="job in newJobs" v-bind:key="job.id" class="ma-2">
                <div v-if="item.content == 'newJobs'">
                  <JobSummary :job="job" :action="item.action"></JobSummary>
                </div>
                </div>
                <div v-if="item.content == 'upcomingJobs'">
                <div v-for="job in upcomingJobs" v-bind:key="job.id" class="ma-2">
                  <JobSummary :job="job" :action="item.action"></JobSummary>
                </div>
                </div>
                <div v-if="item.content == 'activeJobs'">
                <div v-for="job in activeJobs" v-bind:key="job.id" class="ma-2">
                  <JobSummary :job="job" :action="item.action"></JobSummary>
                </div>
                </div>
                <div v-if="item.content == 'completedJobs'">
                <div v-for="job in completedJobs" v-bind:key="job.id" class="ma-2">
                  <JobSummary :job="job" :action="item.action"></JobSummary>
                </div>
                </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-snackbar :value="errors" :timeout="this.$messageTimeout" color="error">
        <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import JobSummary from '@/components/JobSummary.vue'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'Jobs',
  data: () => ({
    items: {
      tabs: [
        { title: 'New', ref: 'new', action: 'new-job', content: 'newJobs' , tip: 'New jobs will show 36 hours before and until 24 hours after the pickup time.'},
        { title: 'Upcoming', ref: 'upcoming', action: 'upcoming-job', content: 'upcomingJobs', tip: 'Upcoming jobs can be started from 1 hour before and until 24 hours after the pickup time.'},
        { title: 'Active', ref: 'active', action: 'active-job', content: 'activeJobs', tip: 'Active jobs can be closed until 24 hours after the pickup time.'},
        { title: 'Completed', ref: 'completed', action: 'completed-job', content: 'completedJobs', tip: 'Completed jobs will show until 7 days after the pickup time.'},
      ]
    },
  }),
  components: {
    TheHeader,
    JobSummary,
  },
  computed: {
    tab: {
      set (tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get () {
        return this.$route.query.tab
      }
    },
    ...mapState({
      errors: state => state.job.errors
    }),
    ...mapGetters(['newJobs', 'upcomingJobs', 'activeJobs', 'completedJobs'])
  },
  created() {
    setInterval(() => this.getNewJobs(), process.env.VUE_APP_FETCH_NEW_JOBS_INTERVAL * 60000)
  },
  mounted() {
    this.getNewJobs()
    this.getUpcomingJobs()
    this.getActiveJobs()
    this.getCompletedJobs()
  },
  methods: {
    getNewJobs() {
      this.$store.dispatch('getNewJobs')
    },
    getUpcomingJobs() {
      this.$store.dispatch('getUpcomingJobs')
    },
    getActiveJobs() {
      this.$store.dispatch('getActiveJobs')
    },
    getCompletedJobs() {
      this.$store.dispatch('getCompletedJobs')
    }
  }
}
</script>

<style scoped>
  ::v-deep .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
    display: none !important;
  }

  ::v-deep .v-tabs-items {
      min-height: calc(100vh - 160px);
  }
</style>
