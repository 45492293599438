import ApiService from '@/services/apiService.js'
import AuthService from '@/services/authService.js'

const state = {
  user: AuthService.getUser() ? AuthService.getUser() : {},
  token: AuthService.getToken() || '',
  errors: null,
  persistentMessages: null,
  authenticated: AuthService.getToken() ? true : false,
}

const mutations = {
  authSuccess(state, { user, token }) {
    state.authenticated = true
    state.user = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      fullname: user.fullname,
      email: user.email,
      picture: user.picture,
    }
    state.token = token.accessToken
    AuthService.setUser(state.user, token.expiresIn + 's')
    AuthService.setToken(state.token, token.expiresIn + 's')
  },
  logout(state) {
    state.authenticated = false
    state.user = {}
    state.token = ''
    state.errors = null
    state.persistentMessages = null
    state.showErrors = false
    AuthService.destroyToken()
    AuthService.destroyUser()
  },
  setErrors(state, errors) {
    state.errors = null
    if (Array.isArray(errors)) {
      state.errors = errors
    } else {
      state.errors = [errors]
    }
  },
  setPersistentMessages(state, messages) {
    state.persistentMessages = null
    if (Array.isArray(messages)) {
      state.persistentMessages = messages
    } else {
      state.persistentMessages = [messages]
    }
  },
  resetErrors(state) {
    state.errors = null
    state.persistentMessages = null
  }
}

const actions = {
  authenticate({ commit }, data) {
    return new Promise(resolve => {
      // Sign in the user via the API service and store the access token and user information
      ApiService.googleSignIn(data)
        .then(response => {
          if (response.data.success) {
            commit('authSuccess', response.data.data)
            resolve(data)
          } else {
            commit('setErrors', response.data.message)
          }
        })
        .catch(error => {
          if (error.response.data) {
            commit('setErrors', error.response.data.messages)
          }
        })
    })
  },
  logout({ commit }) {
    commit('logout')
  },
  clearAuthErrors({ commit }) {
    commit('resetErrors')
  },
}

const getters = {
  isAuthenticated: state => state.authenticated,
}

export default {
  state,
  actions,
  mutations,
  getters
}
