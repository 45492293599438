import ApiClient from '@/helpers/apiClient.js'

const ApiService = {
  googleSignIn (googleIdToken) {
    const data = new FormData()
    data.append('idToken', googleIdToken)
    data.append('userType', 'driver')

    return ApiClient.post('/auth/google/signin', data)
  },

  getJob(values) {
    return ApiClient.get('/jobs/' + values.jobId)
  },

  getNewJobs() {
    return ApiClient.get('/jobs/drivers/me?type=unaccepted')
  },

  getUpcomingJobs() {
    return ApiClient.get('/jobs/drivers/me?type=upcoming')
  },

  getActiveJobs() {
    return ApiClient.get('/jobs/drivers/me?type=active')
  },

  getCompletedJobs() {
    return ApiClient.get('/jobs/drivers/me?type=completed')
  },

  acceptJob(values) {
    const data = new FormData()
    data.append('accept', values.accept)
    data.append('declineReason', values.declineReason)

    return ApiClient.post('/jobs/' + values.jobId + '/acceptance/set', data)
  },

  setJobCommencementStatus(values) {
    const data = new FormData()
    data.append('status', values.status)
    data.append('startTime', values.startTime)
    data.append('mileStart', values.mileStart)
    data.append('problemDescription', values.problemDescription)

    return ApiClient.post('/jobs/' + values.jobId + '/commencement/set', data)
  },

  finishJob(values) {
    const data = new FormData()
    data.append('jobId', values.jobId)
    data.append('amount', values.amount)
    data.append('passengers', values.passengers)
    data.append('mileEnd', values.mileEnd)
    data.append('endTime', values.endTime)
    data.append('description', values.description)

    return ApiClient.post('/jobs/drivers/me/close', data)
  },
}

export default ApiService
