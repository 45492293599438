<template>
  <div class="job-details">
    <v-btn :class="hidden ?  '' : 'd-none'" class="pa-0" @click="show = !show" small text>
      <v-icon class="mr-1">mdi-information</v-icon>
      <span v-if="show">Show Details</span>
      <span v-else>Hide Details</span>
    </v-btn>
    <v-card v-show="!show" flat>
      <v-card-subtitle class="px-0">
        <v-chip :ripple="false" color="secondary">{{ job.id }}</v-chip>
        <v-chip :ripple="false" class="borderless pl-0" outlined>
          <div class="mx-1 passenger-count-icon">
            <span class="secondary--text">{{ job.passengers }}</span>
            <v-icon class="secondary--text">mdi-account</v-icon>
          </div>
          <v-icon class="mx-1 secondary--text" v-if="job.hasLuggage">mdi-briefcase</v-icon>
        </v-chip>
        <v-btn :href="directionsUrl()" target="_blank" class="float-right" color="success" small><v-icon left>mdi-directions-fork</v-icon> Directions</v-btn>
      </v-card-subtitle>
      <v-card-text class="px-0">
        <v-row>
          <v-col cols="4" class="secondary--text">Status</v-col>
          <v-col cols="8">{{ job.status }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="secondary--text">Pickup Time</v-col>
          <v-col cols="8">{{ job.date | humanizeDateTime }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="secondary--text">From</v-col>
          <v-col cols="8">{{ job.pickup.name }}, {{ job.pickup.address }} <span v-if="job.pickup.phone && job.pickup.phone != ''" class="font-italic"><v-btn :href="'tel:' + job.pickup.phone" color="primary" text class="pa-0 ma-0 tel-btn">({{ job.pickup.phone }})</v-btn></span></v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="secondary--text">
            <span v-if="job.type === 'T'">Tour</span>
            <span v-else>To</span>
          </v-col>
          <v-col cols="8">
            <span v-if="job.type === 'T'">{{ job.tour.name }}</span>
            <span v-else>{{ job.dropoff.name }}, {{ job.dropoff.address }} <span v-if="job.dropoff.phone && job.dropoff.phone != ''" class="font-italic"><v-btn :href="'tel:' + job.dropoff.phone" color="primary" text class="pa-0 ma-0 tel-btn">({{ job.dropoff.phone }})</v-btn></span></span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="secondary--text">Driver</v-col>
          <v-col cols="8">{{ job.drivers.main.name }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="secondary--text">Vehicle</v-col>
          <v-col cols="8">{{ job.vehicleRegistrationNumber }}</v-col>
        </v-row>
        <v-row v-if="job.hasLuggage">
          <v-col cols="4" class="secondary--text">Luggage Driver</v-col>
          <v-col cols="8">{{ job.drivers.luggage.name }}</v-col>
        </v-row>
        <v-row v-if="job.hasLuggage">
          <v-col cols="4" class="secondary--text">Luggage Vehicle</v-col>
          <v-col cols="8">{{ job.luggageVehicleRegistrationNumber }}</v-col>
        </v-row>
        <v-row v-if="job.hasFlights">
          <v-col cols="4" class="secondary--text">Flight<span v-if="!job.identicalFareTransport">s</span></v-col>
          <v-col v-if="!job.identicalFareTransport" cols="8">
            <span v-for="flight in uniqueFlights" :key="flight.id">
              <v-col class="px-0 py-0">{{ flight.airline }} - {{ flight.number }}, {{ flight.time | humanizeTime }}</v-col>
            </span>
          </v-col>
          <v-col v-else cols="8">
              <v-col class="px-0 py-0">{{ uniqueFlights[0].airline }} - {{ uniqueFlights[0].number }}, {{ uniqueFlights[0].time | humanizeTime }}</v-col>
          </v-col>
        </v-row>

        <!-- All reservations have same from, to, flight number and pickup time -->
        <v-expansion-panels v-if="job.identicalFareTransport" :value="0" accordion flat class="mt-3">
          <v-expansion-panel>
            <v-expansion-panel-header class="secondary--text"><span><v-icon class="secondary--text">mdi-account</v-icon> Passengers</span></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text v-for="(fare, index) in job.fares" :key="fare.id" class="pa-0" :class="{'pb-3 mb-3': index != job.fares.length - 1}">
                <v-row>
                  <v-col cols="4" class="secondary--text">Name</v-col>
                  <v-col cols="8">{{ fare.firstName }} {{ fare.lastName }} <span class="font-weight-bold">x{{ fare.totalPassengers }}</span>  <span v-if="fare.comments != ''" class="d-block font-italic">{{ fare.comments }}</span> </v-col>
                </v-row>
                <v-row v-if="fare.phoneNumbers.mobile && fare.phoneNumbers.mobile != ''">
                  <v-col cols="4" class="secondary--text">Mobile Phone</v-col>
                  <v-col cols="8"><v-btn :href="'tel:' + fare.phoneNumbers.mobile" color="primary" text class="pa-0 ma-0 tel-btn">{{ fare.phoneNumbers.mobile }}</v-btn></v-col>
                </v-row>
                <v-row v-if="fare.phoneNumbers.home && fare.phoneNumbers.home != ''">
                  <v-col cols="4" class="secondary--text">Local Phone</v-col>
                  <v-col cols="8"><v-btn :href="'tel:' + fare.phoneNumbers.home" color="primary" text class="pa-0 ma-0 tel-btn">{{ fare.phoneNumbers.home }}</v-btn></v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- One or more reservations have different from, to, flight number or pickup time -->
        <!-- Use passenger length to expand all panels -->
        <v-expansion-panels v-if="!job.identicalFareTransport" :value="[]" multiple accordion flat class="mt-3">
          <v-expansion-panel v-for="fare in job.fares" :key="fare.id">
            <v-expansion-panel-header><span><v-icon class="secondary--text">mdi-account</v-icon>{{ fare.firstName }} {{ fare.lastName }} <span class="font-weight-bold">x{{ fare.totalPassengers }}</span></span></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="4" class="secondary--text">Pickup Time</v-col>
                <v-col cols="8">{{ fare.date | humanizeDateTime}}</v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="secondary--text">From</v-col>
                <v-col cols="8">{{ fare.pickup.name }}, {{ fare.pickup.address }} <span v-if="fare.pickup.phone && fare.pickup.phone != ''" class="font-italic"><v-btn :href="'tel:' + fare.pickup.phone" color="primary" text class="pa-0 ma-0 tel-btn">({{ fare.pickup.phone }})</v-btn></span></v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="secondary--text">
                  <span v-if="job.type === 'T'">Tour</span>
                  <span v-else>To</span>
                </v-col>
                <v-col cols="8">
                  <span v-if="job.type === 'T'">{{ job.tour.name}}</span>
                  <span v-else>{{ fare.dropoff.name }}, {{ fare.dropoff.address }} <span v-if="fare.dropoff.phone && fare.dropoff.phone != ''" class="font-italic"><v-btn :href="'tel:' + fare.dropoff.phone" color="primary" text class="pa-0 ma-0 tel-btn">({{ fare.dropoff.phone }})</v-btn></span></span>
                </v-col>
              </v-row>
              <v-row v-if="fare.flight">
                <v-col cols="4" class="secondary--text">Flight</v-col>
                <v-col cols="8">{{ fare.flight.airline }} - {{ fare.flight.number }}, {{ fare.flight.time | humanizeTime }}</v-col>
              </v-row>
              <v-row v-if="fare.phoneNumbers.mobile && fare.phoneNumbers.mobile != ''">
                <v-col cols="4" class="secondary--text">Mobile Phone</v-col>
                <v-col cols="8"><v-btn :href="'tel:' + fare.phoneNumbers.mobile" color="primary" text class="pa-0 ma-0 tel-btn">{{ fare.phoneNumbers.mobile }}</v-btn></v-col>
              </v-row>
              <v-row v-if="fare.phoneNumbers.home && fare.phoneNumbers.home != ''">
                <v-col cols="4" class="secondary--text">Local Phone</v-col>
                <v-col cols="8"><v-btn :href="'tel:' + fare.phoneNumbers.home" color="primary" text class="pa-0 ma-0 tel-btn">{{ fare.phoneNumbers.home }}</v-btn></v-col>
              </v-row>
              <v-row>
                <v-col v-if="fare.comments != ''" cols="4" class="secondary--text">
                  <span>Comments</span>
                </v-col>
                <v-col cols="8">
                  <span>{{ fare.comments }}</span>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels v-if="job.type === 'T'" :value="1" accordion flat class="mt-3">
          <v-expansion-panel>
            <v-expansion-panel-header><span><v-icon class="secondary--text">mdi-routes-clock</v-icon> Itinerary</span></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-for="item in job.tour.itinerary" :key="item.id">
                <v-col v-if="item.time.length || item.description.length" cols="4" class="secondary--text">{{ item.time }}</v-col>
                <v-col v-if="item.time.length || item.description.length"  cols="8">{{ item.description }}</v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'JobDetails',
  props: {
    job: Object,
    hidden: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    show: undefined,
  }),
  mounted() {
    this.show = this.hidden
  },
  computed: {
    uniqueFlights() {
      let flights = {}

      if (this.job.hasFlights) {
        this.job.fares.forEach(fare => {
          if (fare.flight) {
            flights[JSON.stringify(fare.flight)] = fare.flight
          }
        })
      }
      return Object.values(flights)
    }
  },
  methods: {
    directionsUrl() {
      let origLatLng = this.job.pickup ? `${this.job.pickup.latitude},${this.job.pickup.longitude}` : ''
      let destLatLng = this.job.dropoff ? `${this.job.dropoff.latitude},${this.job.dropoff.longitude}` : ''

      let waypoints = []

      // Add the origin as the first waypoint if we have both an origin and destination, otherwise,
      // if we only have an origin or pickup point that should be treated as the destination.
      // This will make Google maps show the driver directions from their current location to the pickup point(s).
      if (origLatLng && destLatLng) {
        waypoints.push(origLatLng)
      } else if (origLatLng && !destLatLng) {
        destLatLng = origLatLng
      }

      // We will use Google map's waypoints to plot any pickup or dropoff points between the job's origin and destination.
      this.job.fares.forEach(fare => {
        if (fare.pickup) {
          waypoints.push(`${fare.pickup.latitude},${fare.pickup.longitude}`)
        }
        if (fare.dropoff) {
          waypoints.push(`${fare.dropoff.latitude},${fare.dropoff.longitude}`)
        }
      })

      waypoints = waypoints.filter(item => ![destLatLng].includes(item)) // Remove the destination location from fare locations we compiled.
      waypoints = [...new Set(waypoints)] // Remove any duplicates from the waypoints we've compiled.

      return `https://www.google.com/maps/dir/?api=1&destination=${destLatLng}&travelmode=driving${waypoints.length ? '&waypoints=' + waypoints.join('|') + '' : ''}`
    },
  }
}
</script>

<style scoped>
  .v-expansion-panel-header {
    padding: 0;
  }

  .col {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .tel-btn {
    height: inherit !important;
  }

  .job-details >>> .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
</style>
