<!--
This component provides a resusable confirmation dialog for actions performed by app users.

In order to function the component requires the parent template to supply a boolean to the "show"
prop which indicates whether the dialog should be displayed or not. Once the dialog is showing the
function passed in the "handler" prop will be called if  the user confirms the action, otherwise if they
cancel the action the $emit('cancel') event will be triggered which let's the parent know to update their
-->
<template>
  <v-dialog v-model="show" persistent>
    <v-card>
      <v-card-title class="grey lighten-3 mb-2">{{ heading }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="$emit('cancel')">{{ cancelButtonText }}</v-btn>
        <v-btn color="success" text @click="handler">{{ confirmButtonText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseConfirmationDialog',
  props: {
    heading: {
      type: String,
      default: 'Confirm',
    },
    message: {
      type: String,
      default: 'Are you sure you want to complete this action?',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
    confirmButtonText: {
      type: String,
      default: 'Confirm',
    },
    // The function that should be called if the user confirms the action.
    handler: Function,
    show: {
      type: Boolean,
      default: false,
    }
  },
}
</script>
