import router from '@/router'
import { getUser } from '@/services/authService.js'

const ROLE_MD = 'Main Driver'
const ROLE_LD = 'Luggage Driver'

/**
 * Determines and returns the logged in driver's role on the specified job.
 * @param {Object} job - A job assigned to the driver which was fetched via jRes' API.
 * @returns {String} "Main Driver" or "Luggage Driver"
 */
export const getJobRole = (job) => {
  let user = getUser()

  // The driver should only be the main OR luggage driver for a job, they should not be both
  // so we return the appropriate string based on the logged in driver's assignment on the job.

  // TODO: REMOVE DRIVER NAME CHECK AFTER 2022-06-30: The reason for using the name is that the user id may not be set until the driver logs out
  // and signs back into the app after this feature is launched, so we will fall back to the name which should suffice until they start a new session which would set the id.
  if ((user.id && job.drivers.main.id == user.id) || job.drivers.main.name == user.fullname) {
    return ROLE_MD
  } else if (job.drivers.luggage && job.drivers.luggage.id) {
    return ROLE_LD
  }
}

/**
 * Indicates whether the logged in driver should be allowed to manage the specified job.
 * @param {Object} job - A job assigned to the driver which was fetched via jRes' API.
 * @returns {boolean} True if the job can be managed, false otherwise.
 */
export const canManageJob = (job) => {
  return getJobRole(job) == ROLE_MD
}

/**
 * Redirects to the correct page if the job status doesn't match the current page it's being displayed on.
 * @param {Object} job - A job assigned to the driver which was fetched via jRes' API.
 * @returns {void}
 */
export const jobStatusCheckAndRedirect = (job) => {
  switch (job.status) {
    case 'Unaccepted' && router.currentRoute.name != 'new-job':
      router.push({ name: 'new-job', params: {id: job.id}})
      break
    case 'Upcoming' && router.currentRoute.name != 'upcoming-job':
      router.push({ name: 'upcoming-job', params: {id: job.id}})
      break
    case 'Active' && router.currentRoute.name != 'active-job':
      router.push({ name: 'active-job', params: {id: job.id}})
      break
    case 'Completed' && router.currentRoute.name != 'completed-job':
    case 'No Show':
    case 'Problematic':
      router.push({ name: 'completed-job', params: {id: job.id}})
      break
  }
}
