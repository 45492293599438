<template>
  <v-card :to="{name: action, params: {id: job.id}}" :hover="true" elevation="2">
    <v-card-subtitle>
      <v-chip :ripple="false" color="secondary">{{ job.id }}</v-chip>
      <v-chip :ripple="false" class="borderless pl-0" outlined>
        <div class="mx-1 passenger-count-icon">
          <span class="secondary--text">{{ job.passengers }}</span>
          <v-icon class="secondary--text">mdi-account</v-icon>
        </div>
        <v-icon class="mx-1 secondary--text" v-if="job.hasLuggage">mdi-briefcase</v-icon>
      </v-chip>
      <v-icon class="float-right">mdi-chevron-right</v-icon>
    </v-card-subtitle>
    <v-card-text class="ma-0">
      <div class="success--text">{{ job.date | humanizeDateTime }}</div>
      <div class="primary--text">{{ role }}</div>
      <div>{{ job.pickup.name }} to {{ job.type == 'R' ? job.dropoff.name : job.tour.name }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import { getJobRole } from '@/services/driverService.js'

export default {
  name: 'JobSummary',
  props: {
    job: Object,
    action: String,
  },
  computed: {
    role() {
      return getJobRole(this.job)
    },
  },
}
</script>
