<template>
    <div class="job-message">
      <v-alert class="mb-0" dense text :type="type" :icon="icon">
        <h3>{{ heading }}</h3>
        <div>{{ message }}</div>
      </v-alert>
    </div>
</template>

<script>
export default {
  name: 'JobMessage',
  props: {
    type: {
      default: 'info',
      type: String,
    },
    icon: {
      type: String,
    },
    heading: {
      type: String,
    },
    message: String,
  },
}
</script>
